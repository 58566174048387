export const baseURL = "https://wiseish.in/api";

export const featuresData = [
    {
      img: "/img/main-feat1.png",
      content:
        "Admin can track individual performance of every sales representative",
    },
    {
      img: "/img/main-feat2.png",
      content: "Admin can monitor everyday store walk-ins",
    },
    {
      img: "/img/main-feat3.png",
      content:
        "Sales representative can Set reminders to follow up with prospects",
    },
    {
      img: "/img/main-feat4.png",
      content:
        "Admin dashboard is enabled with charts for quick data interpretation",
    },
    {
      img: "/img/main-feat5.png",
      content:
        "Get super fast authentic 5 star online reviews from your customers with our Review Boost Technology",
    },
    {
      img: "/img/main-feat6.png",
      content:
        "Follow ups with every customer: walk-ins, call enquiries & multiple channels. Increase conversions by 10x",
    },
  ];

  export const featuresArray = [
    {
      img: `/img/keyImg1.png`,
      content: `Listen to Customers Variable`,
    },
    {
      img: `/img/keyImg2.png`,
      content: `Grow business with Customer Insights`,
    },
    {
      img: `/img/keyImg3.png`,
      content: `Track Performance of Every Sales representative`,
    },
  ];